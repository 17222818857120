import React from 'react'
import { useEffect } from "react";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from '../assets/logo.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import { PlansContext } from "../Context";
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import Userpool from '../Userpool';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#13568a',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const couponCodes = ["NY50", "grab50", "XMAS50"];

export default function PlanPagenew({ hideHeader }: any) {
    const { plans, setPlans, userInfo } = useContext(PlansContext);
    const [plan, setPlan] = useState(false);
    const [responseId, setResponseId] = useState("");
    const [responseState, setResponseStatus] = useState([]);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [coupon, setCoupon] = useState<string>("");
    const [verifiedCoupon, setVerifiedCoupon] = useState<string>("");
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [userId, setUserId] = useState("");
    // const [isCoupon, setIsCoupon] = useState<boolean>(false);
    const handleClose = () => {

        setOpen(false);
        // setVerifiedCoupon("");
        // if(coupon.length > 0){
        //     setIsCoupon(true);
        // }
            
        // navigate('/login');

        // console.log(coupon.length);
    }

    // const info = {
    //     name: 'Manish Rane',
    //     email: "rane.manish.mahadeo@gmail.com"
    // }

    // function removeCode () {
    //     console.log('clicked');
    //     //@ts-ignore
    //     setVerifiedCoupon([]); 
    //     console.log(verifiedCoupon);
    // }

    

    function verifyCode () {
        // const code = e.target.value;



        const verifiedCode = couponCodes.filter(cd => cd === coupon.toLowerCase()) 

        if(verifiedCode.length > 0) {
            // console.log(verifiedCode);
            setVerifiedCoupon(verifiedCode[0])
            // setCodeAccepted(true);
        }

        setCoupon("");
        console.log(verifiedCode)
        // setOpen(false);

    }

    const getCurrentUser = (callback: any) => {
        const cognitoUser = Userpool.getCurrentUser();

        if (!cognitoUser) {
            navigate('/login')
            return false;
        } 

        cognitoUser.getSession((err: any, session: any) => {
            if (err) {
                console.log(err);
                return;
            }

            // console.log("Session valid:" + session.isValid());
            // console.log(session);
            setUserId(session.accessToken.payload.sub)
            // setUserInfo({
            //     user: session.accessToken.payload.username
            // })

            cognitoUser.getUserAttributes((err, attr) => {
                if (err) return console.log(err);

                callback(attr);
            })

            // fetchPlans(session.accessToken.payload.username)
        })
    }

    const planSelected = (plan: string) => {
        let amt;
        switch(plan) {
            case "silver": 
                amt = verifiedCoupon.length > 0 ? "₹499" : "₹999";
                break;
            case "gold":
                amt = verifiedCoupon.length > 0 ? "₹999" : "₹1999";
                break;
            case "diamond":
                amt = verifiedCoupon.length > 0 ? "₹1499" : "₹2999";
                break;
            default:
                amt = "₹799";
                
        }
            

        setPlans({ plan, amt });
        setPlan(true);
        handleRazorpayScreen(Number(amt.slice(1)));
    }

    console.log(plans);
    console.log(userInfo);
    console.log(email, name, phone)
    // console.log(useContext(PlansContext))

    useEffect(() => {
        // console.log(userInfo);
        getCurrentUser((attr: any) => {
            for (let i = 0; i < attr.length; i++) {
                console.log(attr[i].Name)
                if (attr[i].Name === "email") {
                    // console.log(attr[i].)
                    setEmail(attr[i].Value);
                }

                if (attr[i].Name === "phone_number") {
                    setPhone(attr[i].Value);
                }

                if (attr[i].Name === "name") {
                    setName(attr[i].Value);
                }
            }
        })
        setPlan(false);
    }, [])

    const loadScript = (src: string) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    // const createRazorpayOrder = (amount: number) => {
    //     let data = JSON.stringify({
    //         amount: amount * 100,
    //         currency: "INR"
    //     })

    //     let config = {
    //         method: "post",
    //         maxBosyLength: Infinity,
    //         url: "https://node-express-api-vercel-p096h4p73-manishrane060490s-projects.vercel.app/orders",
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         data: data
    //     }

    //     axios.request(config)
    //         .then(response => {
    //             console.log(JSON.stringify(response.data))
    //             handleRazorpayScreen(response.data.amount)
    //         })
    //         .catch(error => {
    //             console.log("error at", error)
    //         })
    // }

    const updatePaymentStatus = (taxnId: string, couponCode:string) => {
        let data = JSON.stringify({
            userId: userId,
            transactionId: taxnId,
            orderId: taxnId,
            paymentStatus: 'success',
            amount: (plans.amt).toString(),
            value1: couponCode,
            value2: 'value1',
            value3: 'value1',
            value4: 'value1',
            value5: 'value1'
        })

        let config = {
            method: "post",
            maxBosyLength: Infinity,
            url: "https://iy5ispsidmfhpzojalaofamqiq0nerep.lambda-url.ap-south-1.on.aws/updatestatus",
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': '3Cp28MeCN19CEJYoox59pamRkuFcj72l3RFdxAYa'
            },
            data: data
        }

        axios.request(config)
            .then(response => {
                console.log(JSON.stringify(response.data))
                // handleRazorpayScreen(response.data.amount)
            })
            .catch(error => {
                console.log("error at", error)
            })
    }

    const updatePlanInDB = (plan: string, couponCode: string) => {
        let data = JSON.stringify({
            userId: userId,
            planType: plan,
            isActive: true,
            from: Date.now().toString(),
            to: Date.now().toString(),
            isUpgraded: true,
            createdOn: Date.now().toString(),
            updatedOn: Date.now().toString(),
            value1: couponCode,
            value2: 'value1',
            value3: 'value1',
            value4: 'value1',
            value5: 'value1',
            planId: ''
        })

        let config = {
            method: "post",
            maxBosyLength: Infinity,
            url: "https://iy5ispsidmfhpzojalaofamqiq0nerep.lambda-url.ap-south-1.on.aws/createnewplan",
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': '3Cp28MeCN19CEJYoox59pamRkuFcj72l3RFdxAYa'
            },
            data: data
        }

        axios.request(config)
            .then(response => {
                console.log(JSON.stringify(response.data))
                // handleRazorpayScreen(response.data.amount)
            })
            .catch(error => {
                console.log("error at", error)
            })
    }

    const handleRazorpayScreen = async (amount: number) => {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

        if (!res) {
            alert("Some error at razorpay screen loading")
            return;
        }

        const options = {
            key: 'rzp_live_fdzzmLwhLkMcG6',
            amount: amount * 100,
            currency: 'INR',
            name: name,
            description: "payment",
            handler: function (response: any) {
                setResponseId(response.razorpay_payment_id);

                updatePlanInDB(plans.plan, verifiedCoupon);
                updatePaymentStatus(response.razorpay_payment_id, verifiedCoupon);
                // navigate('/dashboard');

            },
            prefill: {
                name: name,
                email: email
            },
            theme: {
                color: "#f4c430"
            }
        }

        const paymentObject = new (window as any).Razorpay(options);
        paymentObject.open()
    }

    const options1 = {
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            800: {
                items: 2,
            },
            1000: {
                items: 3,
            }
        },
        nav: true,
        dots: true,
        autoplay: false
    }
    return (
        <div className="plans">
            {
                !hideHeader &&
                <div className="header">
                    <div className="left-side">
                        <Link to="/home">
                            <img src={logo} className="home-logo" alt="Scholar Shark" />
                        </Link>
                    </div>
                    <div className='right-side'>
                        <div className='right-side-item'>
                            <Link to="/dashboard">Dashboard</Link>
                            {/* <Link to="/dashboard">Dashboard</Link> */}
                            {/* <img src={person1} alt='person' className='dropdown-img' /> */}
                        </div>
                    </div>
                </div>
            }

            <div className="container">
                <div className="plansRow">
                    <OwlCarousel className='owl-theme' loop margin={20} {...options1}>

                        {/* 1 */}
                        <div className="review item">
                            <div className="plancardnew text-center silverPlan">
                                <div className="title">
                                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                    <h2>Silver</h2>
                                </div>
                                <div className={`price ${verifiedCoupon? 'coupon-applied' : ''}`}>
                                    <h4><span>₹999</span><span className='discounted-amt'>₹499</span></h4>
                                </div>
                                <div className="option">
                                    <ul>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>This quiz is conducted every week</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Prizes worth 1.2 Lakhs* will be distributed</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Three quizzes to choose from.</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Taking this quiz four times allows you to be eligible for a diamond quiz.</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Quiz Topic to choose from: Cricket, Food or Travel</li>
                                    </ul>
                                </div>
                                <Button variant="outlined" onClick={(e) => {e.preventDefault();setOpen(true)}} className='coupon-btn'>Coupon code</Button>
                                {verifiedCoupon && 
                                    <div className='coupon-title'>
                                        <div>
                                            {verifiedCoupon}
                                            {/* <Button variant='text' onClick={() => removeCode()}>X</Button> */}
                                        </div>
                                    </div>
                                }
                                <button className="carBtn pulse-btn-car" onClick={() => planSelected('silver')}>Challenge Now</button>
                            </div>
                        </div>
                        <div className='review item'>
                            <div className="plancardnew text-center goldPlan">
                                <div className="title">
                                    <i className="fa fa-plane" aria-hidden="true"></i>
                                    <h2>Gold</h2>
                                </div>
                                <div className={`price ${verifiedCoupon.length > 0 ? 'coupon-applied' : ''}`}>
                                    <h4><span>₹1999</span><span className='discounted-amt'>₹999</span></h4>
                                </div>
                                <div className="option">
                                    <ul>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>This quiz is conducted once every month</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Prizes worth 2.5 Lakhs will be distributed</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Three quizzes to choose from</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Taking this quiz twice allows you to be eligible for diamond quiz</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Quiz topic to choose from : Indian Mythology, Indian History or Finance</li>
                                    </ul>
                                </div>
                                <Button variant="outlined" onClick={(e) => {e.preventDefault();setOpen(true)}} className='coupon-btn'>Coupon code</Button>
                                {verifiedCoupon.length > 0 && 
                                    <div className='coupon-title'>
                                        <div>
                                            {verifiedCoupon}
                                            {/* <Button variant='text'>X</Button> */}
                                        </div>
                                    </div>
                                }
                                <button className="carBtn pulse-btn-car" onClick={() => planSelected('gold')}>Challenge Now</button>
                            </div>
                        </div>
                        <div className='review item'>
                            <div className="plancardnew text-center diamondPlan activePlan">
                                <div className="title">
                                    <i className="fa fa-rocket" aria-hidden="true"></i>
                                    <h2>Diamond</h2>
                                </div>
                                <div className={`price ${verifiedCoupon.length > 0 ? 'coupon-applied' : ''}`}>
                                    <h4><span>₹2999</span><span className='discounted-amt'>₹1499</span></h4>
                                </div>
                                <div className="option">
                                    <ul>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>This quiz is the bumper quiz conducted every quarter</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Prizes worth 15CR* will be distributed.</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Only one single quiz choice is available</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Taking this quiz automatically gives you access to one Silver Quiz and one Gold Quiz</li>
                                        <li> <i className="fa fa-check" aria-hidden="true"></i>Questions to be selected in quiz from topic such as Sports, Entertainment, History, Travel, Finance</li>
                                    </ul>
                                </div>
                                <Button variant="outlined" onClick={(e) => {e.preventDefault();setOpen(true)}} className='coupon-btn'>Coupon code</Button>
                                {verifiedCoupon.length > 0 && 
                                    <div className='coupon-title'>
                                        <div>
                                            {verifiedCoupon}
                                            {/* <Button variant='text' onClick={() => setCoupon("")}>X</Button> */}
                                        </div>
                                    </div>
                                }
                                <button className="carBtn pulse-btn-car" onClick={() => planSelected('diamond')}>Challenge Now</button>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2>Enter Coupon Code</h2> 
                    <TextField
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        label="Coupon"
                        fullWidth
                        variant="filled"
                        margin="dense"
                        className="textfield"
                    />
                    {verifiedCoupon.length > 0 && 
                        <div className='coupon-title'>
                            <div>
                                {verifiedCoupon}
                            </div>
                        </div>
                    }
                    <Button variant='contained'  sx={{ mt: 2, mr: 3 }} color='primary' onClick={() => verifyCode()}>Verify</Button>
                    <Button variant='contained' disabled={!(verifiedCoupon.length > 1)}  sx={{ mt: 2 }} color='primary' onClick={() => handleClose()}>Submit</Button>
                </Box>
            </Modal>
        </div>
    )
}
