import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import travelLogo from '../assets/mascot/sage.png';

const MythologyPage = () => {
	const Navigate = useNavigate();

  function carQuizBtn () {
    localStorage.setItem('quizType', 'mythology');
    localStorage.setItem('nextPage', 'silverQuiz');
    Navigate('/login');
  }

  function carBtn () {
    localStorage.setItem('quizType', 'mythology');
    localStorage.setItem('nextPage', 'plans');
    Navigate('/login');
  }

	return (
		<div className='quizPanel blogPanel'>
			<div className='quizPanel-left'>
				<div className='quizPanel-section' style={{ width: "100%", height: "100%" }}>
					<div className='quizPanel-logo' style={{ marginLeft: 0 }}>
						<img src={logo} alt="logo" />
					</div>
					<div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flex: 1 }}>
						<img src={travelLogo} style={{ height: "350px" }} />
					</div>
					<div>
						<h3 style={{textAlign: "center", marginTop: "20px"}}>Quiz Date: 23rd Feb 2025</h3>
						<div className='carBtn-grp'  style={{marginBottom: "20px"}}>
							<button className='carBtn pulse-btn-car' onClick={carBtn}>Challenge Now</button>
                			<button className='carQuizBtn pulse-btn-car' onClick={carQuizBtn}>Practice Quiz</button>
						</div>
					</div>
				</div>
			</div>
			<div className='quizPanel-right'>
				<div>
					<h2 style={{ marginBottom: '20px', textAlign: "center", textDecoration: "underline" }}>Mythology</h2>
					<h3 style={{ marginBottom: '10px' }}>Here are some engaging topics you can practice and prepare for the Mythology quiz</h3>
					<ol>
						<li>
							<b>Epics and Scriptures</b>
							<ul>
								<li>
									Mahabharata: Key characters, events, and lessons.
								</li>
								<li>
									Ramayana: Storyline, major figures, and moral teachings.
								</li>
								<li>
									The Vedas and Upanishads: Important concepts and ideas.
								</li>
								<li>
									The Puranas: Mythical stories of gods and creation.
								</li>
							</ul>
						</li>

						<li>
							<b>Gods and Goddesses</b>
							<ul>
								<li>
									Hindu Trinity: Brahma, Vishnu, and Shiva—roles and symbolism.
								</li>
								<li>
									Goddess Worship: Durga, Kali, Saraswati, and Lakshmi.
								</li>
								<li>
									Avatars of Vishnu: Dashavatara, including Krishna, Rama, and Narasimha.
								</li>

							</ul>
						</li>

						<li>
							<b>Famous Mythological Battles</b>
							<ul>
								<li>
									Kurukshetra War: Key players and important events.
								</li>
								<li>
									Ram-Ravana Yuddh: Major incidents and strategies.
								</li>
								<li>
									Devas vs Asuras: Symbolism and stories behind their conflicts.
								</li>
							</ul>
						</li>

						<li>
							<b>Symbolism and Iconography</b>
							<ul>
								<li>
									The role of animals (Garuda, Nandi, Hanuman).
								</li>
								<li>
									Weapons associated with deities (Sudarshan Chakra, Trishul, etc.).
								</li>
								<li>
									Mounts (Vahana) of various gods and their significance.
								</li>
							</ul>
						</li>

						<li>
							<b>Lesser-known Characters and Stories</b>
							<ul>
								<li>
									Shikhandi from the Mahabharata.
								</li>
								<li>
									King Harishchandra’s story of truthfulness.
								</li>
								<li>
									Karna’s life journey and internal conflicts.
								</li>
							</ul>
						</li>

						<li>
							<b>Mythical Creatures</b>
							<ul>
								<li>
									Garuda, Nagas, Yakshas, and Gandharvas.
								</li>
								<li>
									Rakshasas like Ravana and Kumbhakarna.
								</li>
								<li>
									Vedic celestial beings and their roles.
								</li>
							</ul>
						</li>

						<li>
							<b>Holy Places and Mythological Significance</b>
							<ul>
								<li>
									Kashi (Varanasi) and its mythological history.
								</li>
								<li>
									Kailash Parvat as Lord Shiva’s abode.
								</li>
								<li>
									Sacred rivers like the Ganga and their origins in mythology.
								</li>
							</ul>
						</li>

						<li>
							<b>Incarnations and Rebirths</b>
							<ul>
								<li>
									Concepts of Karma, Dharma, and rebirth in mythology.
								</li>
								<li>
									The story of Parashurama and his repeated incarnations.
								</li>
								<li>
									Sage Narada and his involvement in various births.
								</li>
							</ul>
						</li>

						<li>
							<b>Mythological Love Stories</b>
							<ul>
								<li>
									Shiva-Parvati: Story of marriage and love.
								</li>
								<li>
									Radha-Krishna: Symbol of eternal love.
								</li>
								<li>
									Savitri and Satyavan: Tale of devotion and bringing her husband back from death.
								</li>
							</ul>
						</li>

						<li>
							<b>Mythological Lessons and Morals</b>
							<ul>
								<li>
									Karma and Dharma in the Mahabharata.
								</li>
								<li>
									Lessons on humility from the story of Bali and Vamana.
								</li>
								<li>
									The power of devotion and faith through the story of Prahlada.
								</li>
							</ul>
						</li>

					</ol>
				</div>
			</div>
			<Link to="/home" className='close'>
				X
			</Link>
		</div>
	)
}

export default MythologyPage

