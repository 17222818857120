import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import travelLogo from '../assets/mascot/cricket.png';

const CricketPage = () => {

  const Navigate = useNavigate();

  function carQuizBtn () {
    localStorage.setItem('quizType', 'cricket');
    localStorage.setItem('nextPage', 'silverQuiz');
    Navigate('/login');
  }

  function carBtn () {
    localStorage.setItem('quizType', 'cricket');
    localStorage.setItem('nextPage', 'plans');
    Navigate('/login');
  }

  return (
    <div className='quizPanel blogPanel'>
      <div className='quizPanel-left'>
        <div className='quizPanel-section' style={{width: "100%", height: "100%"}}>
          <div className='quizPanel-logo' style={{marginLeft: 0}}>
            <img src={logo} alt="logo" />
          </div>
          <div style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flex: 1}}>
            <img src={travelLogo} style={{height: "350px"}} />
          </div>
          <div>
            <h3 style={{textAlign: "center", marginTop: "20px"}}>Quiz Date: 26th Jan 2025</h3>
            <div className='carBtn-grp' style={{marginBottom: "20px"}}>
                <button className='carBtn pulse-btn-car' onClick={carBtn}>Challenge Now</button>
                <button className='carQuizBtn pulse-btn-car' onClick={carQuizBtn}>Practice Quiz</button>
            </div>
          </div>
        </div>
      </div>
      <div className='quizPanel-right'>
        <div>
          <h2 style={{marginBottom: '20px', textAlign: "center", textDecoration: "underline"}}>Cricket</h2>
          <h3 style={{marginBottom: '10px'}}>Here are some engaging topics you can practice and prepare for the cricket quiz</h3>
          <ol>
            <li>
              <b>Cricket World Cups:</b> Questions on winners, runners-up, records, and standout moments from various editions of the World Cup.
            </li>
            <li>
              <b>Famous Players:</b>Focus on legendary players like Sachin Tendulkar, Brian Lara, Ricky Ponting, Virat Kohli, and MS Dhoni, including their stats, records, and milestones.
            </li>
            <li>
              <b>Cricket Grounds: </b>Iconic stadiums and memorable matches played at places like Lord's, the MCG, Eden Gardens, and Wankhede Stadium.
            </li>
            <li>
              <b>Cricket Rules & Formats: </b>Questions on the laws of the game, different formats (Test, ODI, T20), and significant changes in the rules over the years.
            </li>
            <li>
              <b>Historic Matches: </b> Famous Test matches, ODIs, and T20 games, including events like tied matches or record-breaking innings.
            </li>
            <li>
              <b>Captains and Leadership: </b> Quizzing on captains and their leadership achievements, especially those who led their teams to victories in major tournaments.
            </li>
            <li>
              <b>Records and Milestones: </b>Questions about cricket records, including fastest centuries, highest runs, most wickets, and unique achievements.
            </li>
            <li>
              <b>IPL (Indian Premier League): </b>Questions on IPL teams, star performers, auction records, and thrilling moments from the tournament.
            </li>
            <li>
              <b>Cricket Controversies: </b>Infamous incidents like ball-tampering scandals, match-fixing cases, or on-field controversies.
            </li>
            <li>
              <b>Cricketing Rivalries: </b>Focus on epic rivalries like India vs. Pakistan, Australia vs. England (The Ashes), and other famous rivalries across nations.
            </li>
            <li>
              <b>Women’s Cricket: </b>Questions on achievements, standout players, and memorable tournaments in women’s cricket.
            </li>
            <li>
              <b>Cricketing Equipment and Evolution: </b>Questions about how cricket bats, balls, and protective gear have evolved over time.
            </li>
            <li>
              <b>Domestic Cricket: </b>Knowledge about Ranji Trophy, Sheffield Shield, and other major domestic cricket tournaments across the world.
            </li>
            <li>
              <b>Cricketing Strategies: </b>Questions on field placements, captaincy tactics, or game strategies that changed the outcome of matches.
            </li>
            <li>
              <b>Cricketing Terms and Jargon: </b>Testing knowledge on terms like 'Duckworth-Lewis Method', 'Hat-trick,' 'Golden Duck', 'YORKER', etc.
            </li>
          </ol>
        </div>
      </div>
      <Link to="/home" className='close'>
        X
      </Link>
    </div>
  )
}

export default CricketPage
