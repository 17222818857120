import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import travelLogo from '../assets/mascot/traveler.png';

const TravelPage = () => {
  const Navigate = useNavigate();

  function carQuizBtn () {
    localStorage.setItem('quizType', 'travel');
    localStorage.setItem('nextPage', 'silverQuiz');
    Navigate('/login');
  }

  function carBtn () {
    localStorage.setItem('quizType', 'travel');
    localStorage.setItem('nextPage', 'plans');
    Navigate('/login');
  }
  return (
    <div className='quizPanel blogPanel'>
      <div className='quizPanel-left'>
        <div className='quizPanel-section' style={{width: "100%", height: "100%"}}>
          <div className='quizPanel-logo' style={{marginLeft: 0}}>
            <img src={logo} alt="logo" />
          </div>
          <div style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flex: 1}}>
            <img src={travelLogo} style={{height: "350px"}} />
          </div>
          <div>
            <h3 style={{textAlign: "center", marginTop: "20px"}}>Quiz Date: 26th Jan 2025</h3>
            <div className='carBtn-grp' style={{marginBottom: "20px"}}>
              <button className='carBtn pulse-btn-car' onClick={carBtn}>Challenge Now</button>
              <button className='carQuizBtn pulse-btn-car' onClick={carQuizBtn}>Practice Quiz</button>
            </div>
          </div>
        </div>
      </div>
      <div className='quizPanel-right'>
        <div>
          <h2 style={{marginBottom: '20px', textAlign: "center", textDecoration: "underline"}}>Travel</h2>
          <h3 style={{marginBottom: '10px'}}>Here are some engaging topics you can practice and prepare for the travel quiz on India.</h3>
          <ol>
            <li>
              <b>Famous Landmarks:</b> Questions on iconic landmarks like the Taj Mahal, Qutub Minar, Gateway of India, and Charminar.
            </li>
            <li>
              <b>UNESCO World Heritage Sites:</b> Quiz on India’s UNESCO heritage sites such as Ajanta and Ellora Caves, Khajuraho Temples, and Kaziranga National Park.
            </li>
            <li>
              <b>Natural Wonders:</b> Famous natural attractions like the Himalayas, Valley of Flowers, Sundarbans, and the backwaters of Kerala.
            </li>
            <li>
              <b>State Capitals & Major Cities:</b> Knowledge of capitals and major cities and their unique attractions like Jaipur, Kochi, and Chandigarh.
            </li>
            <li>
              <b>Hill Stations:</b> Focus on popular hill stations like Shimla, Darjeeling, Manali, and Ooty.
            </li>
            <li>
              <b>Beaches in India:</b> Questions on famous beach destinations such as Goa, Kovalam, and Puri.
            </li>
            <li>
              <b>National Parks & Wildlife Sanctuaries:</b> Topics can include Corbett National Park, Ranthambore, Periyar Wildlife Sanctuary, and the Great Indian Bustard Sanctuary.
            </li>
            <li>
              <b>Cultural Festivals:</b> Travel during major festivals like Pushkar Mela, Hornbill Festival, Durga Puja, and the Desert Festival of Jaisalmer.
            </li>
            <li>
              <b>Religious Pilgrimages:</b> Famous pilgrimage spots like Varanasi, Rishikesh, Haridwar, Vaishno Devi, and the Golden Temple.
            </li>
            <li>
              <b>Cuisine by Region:</b> Questions about the unique local cuisines one can experience while traveling in different states of India.
            </li>
            <li>
              <b>Historical Sites:</b> Topics on ancient and medieval historical sites such as Hampi, Fatehpur Sikri, and Mahabalipuram.
            </li>
            <li>
              <b>Famous Rivers:</b> Questions on major rivers like the Ganga, Yamuna, Brahmaputra, and Godavari and the cities they pass through.
            </li>
            <li>
              <b>Adventure Travel:</b> Adventure travel destinations for trekking, river rafting, skiing, and paragliding in places like Ladakh, Rishikesh, and Himachal Pradesh.
            </li>
            <li>
              <b>Popular Road Trips:</b> Questions on iconic road trips like the Leh-Manali highway or the East Coast Road in Tamil Nadu.
            </li>
            <li>
              <b>Tribal Tourism:</b> Quiz on regions like Chhattisgarh, Nagaland, or Orissa where tribal cultures are a major draw for travellers.
            </li>
          </ol>
        </div>
      </div>
      <Link to="/home" className='close'>
        X
      </Link>
    </div>
  )
}

export default TravelPage

