import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import travelLogo from '../assets/mascot/cricket.png';

const DiamondPage = () => {
  return (
    <div className='quizPanel blogPanel'>
      <div className='quizPanel-left'>
        <div className='quizPanel-section' style={{width: "100%", height: "100%"}}>
          <div className='quizPanel-logo' style={{marginLeft: 0}}>
            <img src={logo} alt="logo" />
          </div>
          <div style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flex: 1}}>
            <img src={travelLogo} style={{height: "350px"}} />
          </div>
          <div>
            <h3 style={{textAlign: "center", marginTop: "20px"}}>Quiz Date: 30th Mar 2025</h3>
            <div className='carBtn-grp' style={{marginBottom: "20px"}}>
                <button className='carBtn pulse-btn-car'>Challenge Now</button>
                <button className='carQuizBtn pulse-btn-car'>Practice Quiz</button>
            </div>
          </div>
        </div>
      </div>
      <div className='quizPanel-right'>
        <div>
          <h2 style={{marginBottom: '20px', textAlign: "center", textDecoration: "underline"}}>Diamond</h2>
          <h3 style={{marginBottom: '10px'}}>Welcome to the most <b>thrilling and diverse quiz challenge</b> you’ve ever encountered! Here’s how it works:</h3>
		  
			You get to <b>craft your own quiz journey</b> by selecting <b>2 topics from each of the 5 categories below</b>. What makes this even more exciting is the <b>freedom to eliminate 1 entire category</b> if you're not confident in it, and select 2 topics from the remaining 4 categories.
			Let’s break it down!

		  
          <ol>
            <li>
              <b>Sports:</b> Are you a sports enthusiast? Prove it by <b>choosing 2 topics</b> from these fan-favorite sports:
			  <ul>
					<li>
						<b>Cricket</b>: Test your knowledge on the gentlemen's game.
					</li>
					<li>
						<b>Football</b>:From World Cups to clubs, show your football IQ.
					</li>
					<li>
						<b>Chess</b>:Dive into the world of grandmasters and strategies.
					</li>
					<li>
						<b>Hockey</b>:Celebrate the magic of India's national sport.
					</li>
					<li>
						<b>Badminton</b>:Shuttle your way through questions on this fast-paced game.
					</li>
			  </ul>
            </li>
			
			<li>
              <b>Entertainment</b> Love everything about films and pop culture? Pick 2 topics that excite you the most:
			  <ul>
					<li>
						<b>Movies: </b>Get ready to answer all about blockbuster hits and indie gems.
					</li>
					<li>
						<b>Actors: </b>Think you know your favorite stars inside out? Time to prove it.
					</li>
					<li>
						<b>Star Kids: </b>Guess who’s who in the glamorous world of celeb families.
					</li>				
					<li>
						<b>Dialogues: </b>Can you recall famous one-liners and punchy dialogues?
					</li>
					<li>
						<b>Songs: </b>Match the lyrics, composers, and hit tracks with their movies.
					</li>
			  </ul>
            </li>
            
			<li>
              <b>Travel</b> Explore the beauty of India by picking 2 travel-related topics:
			  <ul>
					<li>
						<b>Iconic Locations: </b> Identify popular destinations, tourist spots, and must-visit places.
					</li>
					<li>
						<b>Famous Dishes of India: </b>Recognize regional dishes and where they’re from.
					</li>
					
					<li>
						<b>Capitals of Indian States:</b> Test your knowledge on India’s state capitals.
					</li>
					
					<li>
						<b>Historical Monuments: </b>From the Taj Mahal to Red Fort, show your monument mastery.
					</li>
					
					<li>
						<b>Transport Facilities: </b>From the Taj Mahal to Red Fort, show your monument mastery.
					
					</li>
			  </ul>
            </li>
			
			<li>
              <b>History</b> Do you have what it takes to conquer the past? Choose 2 topics that fascinate you:
			  <ul>
					<li>
						<b>Great Kings:</b> Explore the lives and reigns of India’s most renowned rulers..

					</li>
					<li>
						<b>Dynasties Period: </b>Test your knowledge on the empires that shaped India.
					</li>
					<li>
						<b>Important Battles: </b>Relive the battles that defined our nation.
					</li>
					<li>
						<b>Freedom Struggle: </b>Dive deep into India’s fight for independence.
					</li>
					
					<li>
						<b>Freedom Fighters: </b>Honor the heroes who sacrificed for India’s freedom.
					</li>
			  </ul>
            </li>
			
			<li>
              <b>Finance</b> Are you financially savvy? Choose 2 topics from the world of money:
			  <ul>
					<li>
						<b>Equity Markets: </b> Show your stock market prowess.
					</li>
					
					<li>
						<b>Mutual Funds: </b>Navigate through investment funds and strategies.
					</li>
					
					<li>
						<b>Insurance: </b>From life to health, know your policies inside and out.
					</li>
					
					<li>
						<b>Investments: </b>Get ready for questions on how to grow your wealth.
					</li>
					
					<li>
						<b>Personal Finance: </b>Learn the ins and outs of managing money and making smart decisions.
					</li>
					
			  </ul>
            </li>
			
			
			
          </ol>
        </div>
      </div>
	  <Link to="/home" className='close'>
		X
	  </Link>
    </div>
  )
}

export default DiamondPage
